.main-container {
  background-color: rgb(220, 220, 220);
}

.head {
  padding: 10px 0 0 0;
  margin-bottom: 5px;
  filter: drop-shadow(0px 9px 5px rgba(76, 76, 76, 0.3));
}

.head-div {
  background-color: #e8e8e8;
}

.head-body {
  background-color: white;
}

.head-titel {
  max-width: 80%;
  color: rgb(202, 202, 202);
  /* filter: drop-shadow(3px 3px 3px rgba(0, 131, 2, 0.734)); */
  /* filter: drop-shadow(4px 4px 6px rgba(172, 172, 172, 0.4));   */
}

.head-titel-line {
  max-width: 80%;
  /* filter: drop-shadow(3px 3px 3px rgba(0, 131, 2, 0.734)); */
  color: rgb(202, 202, 202);
  filter: drop-shadow(4px 4px 6px rgba(172, 172, 172, 0.4));
}

.center-component {
  align-items: center;
}

.cards {
  min-height: max-content;
}

.cards-form {
  margin-bottom: 10px;
}

.cards-body {
  background-color: rgb(214, 243, 217);
}

.card-body-confirm {
  min-height: 300px;
}

.mb-3 span {
  font-weight: bold;
  color: rgb(51, 121, 58);
}

.form-group-radio {
  filter: drop-shadow(4px 4px 4px rgba(76, 76, 76, 0.3));
}

.App-link {
  color: #61dafb;
}

.submit-buttons {
  display: flex;
  float: right;
  margin-top: 10px;
}

.center-wide {
  display: flex;
}

.form-group-radio {
  display: flex;
  flex-wrap: wrap;
}

.form-group-radio-button {
  margin: 10px;
  filter: drop-shadow(4px 4px 6px rgba(47, 47, 47, 0.8));
}

.modal-info-background, .modal-close-background {
  position: fixed;
  top: 0px;
  right: 0px;
  margin-right: 0px;
  align-items: center;
  background-image: linear-gradient(45deg,
      black 25%,
      yellow 25%,
      yellow 50%,
      black 50%,
      black 75%,
      yellow 75%,
      yellow 100%);
  background-size: 28.28px 28.28px;
  justify-content: center;
  filter: drop-shadow(4px 4px 6px rgba(76, 76, 76, 0.8));
}

.modal-close-background {
  position: relative;
}

.modal-info-button {
  margin: 10px 10px 10px 10px;
  filter: drop-shadow(4px 4px 6px rgba(76, 76, 76, 0.8));
}

.modal-info-button div {
  display: inline-flex;
}

.modal-title {
  background-image: linear-gradient(45deg,
      black 25%,
      yellow 25%,
      yellow 50%,
      black 50%,
      black 75%,
      yellow 75%,
      yellow 100%);
  background-size: 28.28px 28.28px;
}

.modal-text {
  text-align: justify;
}

.q3-select-title {
  font-weight: bold;
  color: rgb(51, 121, 58);
}

.bottom {
  margin-top: 10px;
  text-align: center;
  color: rgb(159, 159, 159);
  font-size: small;
}