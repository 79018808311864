body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  background-color: rgb(220, 220, 220);
}

.head {
  padding: 10px 0 0 0;
  margin-bottom: 5px;
  -webkit-filter: drop-shadow(0px 9px 5px rgba(76, 76, 76, 0.3));
          filter: drop-shadow(0px 9px 5px rgba(76, 76, 76, 0.3));
}

.head-div {
  background-color: #e8e8e8;
}

.head-body {
  background-color: white;
}

.head-titel {
  max-width: 80%;
  color: rgb(202, 202, 202);
  /* filter: drop-shadow(3px 3px 3px rgba(0, 131, 2, 0.734)); */
  /* filter: drop-shadow(4px 4px 6px rgba(172, 172, 172, 0.4));   */
}

.head-titel-line {
  max-width: 80%;
  /* filter: drop-shadow(3px 3px 3px rgba(0, 131, 2, 0.734)); */
  color: rgb(202, 202, 202);
  -webkit-filter: drop-shadow(4px 4px 6px rgba(172, 172, 172, 0.4));
          filter: drop-shadow(4px 4px 6px rgba(172, 172, 172, 0.4));
}

.center-component {
  align-items: center;
}

.cards {
  min-height: -webkit-max-content;
  min-height: max-content;
}

.cards-form {
  margin-bottom: 10px;
}

.cards-body {
  background-color: rgb(214, 243, 217);
}

.card-body-confirm {
  min-height: 300px;
}

.mb-3 span {
  font-weight: bold;
  color: rgb(51, 121, 58);
}

.form-group-radio {
  -webkit-filter: drop-shadow(4px 4px 4px rgba(76, 76, 76, 0.3));
          filter: drop-shadow(4px 4px 4px rgba(76, 76, 76, 0.3));
}

.App-link {
  color: #61dafb;
}

.submit-buttons {
  display: flex;
  float: right;
  margin-top: 10px;
}

.center-wide {
  display: flex;
}

.form-group-radio {
  display: flex;
  flex-wrap: wrap;
}

.form-group-radio-button {
  margin: 10px;
  -webkit-filter: drop-shadow(4px 4px 6px rgba(47, 47, 47, 0.8));
          filter: drop-shadow(4px 4px 6px rgba(47, 47, 47, 0.8));
}

.modal-info-background, .modal-close-background {
  position: fixed;
  top: 0px;
  right: 0px;
  margin-right: 0px;
  align-items: center;
  background-image: linear-gradient(45deg,
      black 25%,
      yellow 25%,
      yellow 50%,
      black 50%,
      black 75%,
      yellow 75%,
      yellow 100%);
  background-size: 28.28px 28.28px;
  justify-content: center;
  -webkit-filter: drop-shadow(4px 4px 6px rgba(76, 76, 76, 0.8));
          filter: drop-shadow(4px 4px 6px rgba(76, 76, 76, 0.8));
}

.modal-close-background {
  position: relative;
}

.modal-info-button {
  margin: 10px 10px 10px 10px;
  -webkit-filter: drop-shadow(4px 4px 6px rgba(76, 76, 76, 0.8));
          filter: drop-shadow(4px 4px 6px rgba(76, 76, 76, 0.8));
}

.modal-info-button div {
  display: inline-flex;
}

.modal-title {
  background-image: linear-gradient(45deg,
      black 25%,
      yellow 25%,
      yellow 50%,
      black 50%,
      black 75%,
      yellow 75%,
      yellow 100%);
  background-size: 28.28px 28.28px;
}

.modal-text {
  text-align: justify;
}

.q3-select-title {
  font-weight: bold;
  color: rgb(51, 121, 58);
}

.bottom {
  margin-top: 10px;
  text-align: center;
  color: rgb(159, 159, 159);
  font-size: small;
}
.cookies-container {
    display: block;
    position:fixed;
    bottom: 0;
    min-height: 150px;
    background: rgb(206, 206, 206);
    background: linear-gradient(5deg, rgba(206, 206, 206, 1) 0%, rgba(172, 172, 172, 1) 61%, rgba(235, 235, 235, 1) 100%);
    box-shadow: 0px -3px 15px 2px rgba(0, 0, 0, 0.436);
    text-align: center;
    margin: 0 0px 0 0px;
    border: 1px solid grey;
    border-radius: 8px;
    z-index: 100;
}

.cookies-container button {
    border: 1px solid grey;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    background-color: lightblue;
}

.cookies-title {
    font-size: large;
    font-weight: bold;
    padding: 0 0 0 15px;
}

.cookies-text {
    color: rgb(86, 86, 86);
    padding: 0 10px 0 10px;    
}

