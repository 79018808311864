.cookies-container {
    display: block;
    position:fixed;
    bottom: 0;
    min-height: 150px;
    background: rgb(206, 206, 206);
    background: linear-gradient(5deg, rgba(206, 206, 206, 1) 0%, rgba(172, 172, 172, 1) 61%, rgba(235, 235, 235, 1) 100%);
    box-shadow: 0px -3px 15px 2px rgba(0, 0, 0, 0.436);
    text-align: center;
    margin: 0 0px 0 0px;
    border: 1px solid grey;
    border-radius: 8px;
    z-index: 100;
}

.cookies-container button {
    border: 1px solid grey;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    background-color: lightblue;
}

.cookies-title {
    font-size: large;
    font-weight: bold;
    padding: 0 0 0 15px;
}

.cookies-text {
    color: rgb(86, 86, 86);
    padding: 0 10px 0 10px;    
}
